<template>
  <div style="background: black">
    <html>
      <body
        class="enable-animations layout-column"
        shortcuts=""
        style="
          background: black;
          height: 100vh;
          min-height: 100%;
          margin: 0;
          padding: 0;
          font-family: Roboto, sans-serif;
          color: #2f3234;
          overflow: hidden !important;
        "
      >
        <div class="space">
          <div id="stars" class="stars"></div>
          <div id="stars2" class="stars2"></div>
          <div id="stars3" class="stars3"></div>
          <div id="solar-syst" class="solar-syst">
            <div class="sun"></div>
            <div class="mercury"></div>
            <div class="venus"></div>
            <div class="earth"></div>
            <div class="mars"></div>
            <div class="jupiter"></div>
            <div class="saturn"></div>
            <div class="uranus"></div>
            <div class="neptune"></div>
            <div class="pluto"></div>
            <div class="asteroids-belt"></div>
          </div>
        </div>
      </body>
    </html>
    <div
      v-if="!insertStatus"
      style="height: 100vh; width: 100vw; position: absolute; top: 0"
    >
      <el-form
        :model="ruleForm2"
        :rules="rules2"
        status-icon
        ref="ruleForm2"
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <h3 class="title">系统登录</h3>
        <el-form-item prop="mobile">
          <el-input
            type="text"
            v-model="ruleForm2.mobile"
            auto-complete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input
            type="password"
            v-model="ruleForm2.pwd"
            auto-complete="off"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <div style="display: flex; justify-content: space-between">
          <el-checkbox v-model="checked" class="rememberme"
            >记住密码</el-checkbox
          >
          <div @click="insertStatus = !insertStatus">注册账号</div>
        </div>

        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click="handleSubmit"
            :loading="logining"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div
      v-if="insertStatus"
      style="height: 100vh; width: 100vw; position: absolute; top: 0"
    >
      <el-form
        status-icon
        ref="ruleForm2"
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <h3 class="title">系统登录</h3>
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="insertForm.mobile"
            placeholder="手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="insertForm.pwd"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="insertForm.pwd2"
            placeholder="再次确认密码"
          ></el-input>
        </el-form-item>

        <div style="width: 100%; display: flex">
          <el-button type="primary" style="width: 100%" @click="userInsert()">
            注册
          </el-button>
          <el-button
            type="primary"
            style="width: 100%"
            @click="insertStatus = !insertStatus"
          >
            返回登录
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
  <!--      -->
</template>

<script>
import { userInsert, login } from "@/utils/masterdata.js";
export default {
  data() {
    return {
      insertStatus: false,
      logining: false,
      insertForm: {},
      ruleForm2: {},
      rules2: {
        mobile: [
          {
            required: true,
            message: "please enter your account",
            trigger: "blur",
          },
        ],
        pwd: [
          { required: true, message: "enter your password", trigger: "blur" },
        ],
      },
      checked: false,
    };
  },
  methods: {
    async userInsert() {
      if (!this.insertForm.mobile) return this.$message.error("请输入手机号");
      if (!this.insertForm.pwd) return this.$message.error("请输入密码");
      if (!this.insertForm.pwd2) return this.$message.error("请再次输入密码");
      if (this.insertForm.pwd != this.insertForm.pwd2)
        return this.$message.error("两次密码输入不正确");
      let data = await userInsert(this.insertForm);
      if (data.heads.code == 200) {
        this.$message.success("注册成功,请前往登录");
        this.insertStatus = !this.insertStatus;
      }
      console.log();
    },
    async handleSubmit() {
      this.$refs.ruleForm2.validate(async (valid) => {
        if (valid) {
          let data = await login(this.ruleForm2);
          console.log(data);
          if (data.heads.code == 200) {
            localStorage.setItem("bigdatatoken", data.body.token);
            this.$message.success("登录成功");
            this.$router.push("/index");
          }
          this.logining = true;
        } else {
          return false;
        }
      });
      //   this.$router.push({
      //     path: "/index",
      //   });
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
}

.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 350px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
  position: relative;
  z-index: 1999999999;
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
<style lang="less" scoped>
html {
  background-image: url(https://img.cdn.xingningjt.com/uploads//1644411614/1123.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}

.common-layout {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  //background: url(../../assets/img/backgroundimage.png) #b8e5f8 no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .login {
    width: 400px;
    height: 488px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -280px;

    img {
      width: 300px;
      margin: 0 50px 30px 50px;
    }
  }

  .login_content {
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    padding: 40px 50px;
    box-shadow: 0 7px 15px #504f4fa1;
    text-align: center;

    img {
      width: 280px;
      margin: -20px 0px 26px 0px;
    }

    p {
      color: #121212;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 16px;
    }

    .wx {
      color: #888;
      font-size: 14px;
      margin: 20px 0 -10px;
    }
  }

  .footer {
    background: #f6f6f6;
    text-align: center;
    border-radius: 0 0 10px 10px;
    padding: 15px;
  }
}

.wxlogin_border {
  width: 300px;
  height: 200px;
}
</style>
<style scoped src="./solar.css">
</style>
<style scoped src="./stars.css">
</style>
